<template>
  <v-container>
    <h1 class="title mb-10">{{ title }}</h1>
    <slot />
  </v-container>
</template>
<script>
export default {
  name: 'Title',
  props: ['title'],
};
</script>
<style lang="scss" scoped></style>
