<template>
  <title-wrapper title="Lista pracowników" ref="titleWrapper">
    <loading :loading="loading">
      <user-data-table
        :data="employees"
        :headers="headers"
        :length="length"
        :page.sync="params.page"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        @update:sort-by="sortByFunc"
        @update:sort-desc="sortDescFunc"
        :loading="loading"
      >
        <template v-slot:filters>
          <user-filters
            @updateRole="onRoleUpdate"
            :statisticsActive="!roleIsUnselectedOrOffice"
            @search="search"
            @clearParams="clearParams"
          ></user-filters>
        </template>
        <template v-slot:actions="slotProps">
          <table-popup-router
            :to="{
              name: 'EditEmployee',
              params: { userId: slotProps.item._id },
            }"
            content="Edytuj użytkownika"
          />
          <v-list-item class="hover-item-popup" @click="blockUser(slotProps.item)">
            <v-list-item-content>{{
              returnBlockUserText(slotProps.item.disabled)
            }}</v-list-item-content>
          </v-list-item>
        </template>
      </user-data-table>
    </loading>
    <primary-button :to="{ name: 'AddEmployee' }"
      ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon>Dodaj użytkownika</primary-button
    >
  </title-wrapper>
</template>
<script>
import Loading from '../../components/Loading.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
import UserDataTable from '../../components/user/UserDataTable.vue';
import { headersWithoutStatistics, headersWithStatistics } from '../../headers/userListHeaders';
import { sortHandler } from '../../mixins/sortHandler';
import { formatDate } from '../../mixins/formatDate';
import TablePopupRouter from '../../components/TablePopupRouter.vue';
import UserFilters from '../../components/user/UserFilters.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
import roles from '../../static/roles';
import { isEmpty, isEqual } from 'lodash';

export default {
  components: {
    TitleWrapper,
    Loading,
    UserDataTable,
    TablePopupRouter,
    UserFilters,
    PrimaryButton,
  },
  mixins: [sortHandler, formatDate],
  data() {
    return {
      employees: [],
      loading: true,
      error: null,
      filters: {
        disabled: false,
      },
      params: {
        page: 1,
        limit: 7,
        sort: '',
        role: '',
      },
      length: 0,
    };
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    clearParams() {
      this.filters = {
        disabled: false,
      };
      this.params = {
        page: 1,
        limit: 10,
        sort: '',
        role: '',
      };
      this.getFiltered();
    },
    search(filters) {
      this.filters = {
        ...filters,
      };
      this.getFiltered();
    },
    getParams() {
      if (!this.roleIsUnselectedOrOffice) {
        this.mapDateFilters();
      }
      return {
        ...this.params,
        ...this.filters,
      };
    },
    mapDateFilters() {
      this.filters.fromDate = this.formatDateISO(this.filters.fromDate);
      this.filters.toDate = this.formatDateISO(
        this.filters.toDate ? this.filters.toDate : new Date()
      );
    },
    async init() {
      this.loading = true;
      try {
        await this.getFiltered();
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async getFiltered() {
      try {
        const response = await this.$store.dispatch('user/getAll', this.getParams());
        this.employees = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        this.error = error;
      }
    },
    async blockUser(user) {
      try {
        await this.$store.dispatch('user/updateUser', {
          userId: user._id,
          user: { ...user, disabled: !user.disabled },
        });

        this.$toasted.global.success({
          message: `Konto ${user.email} zostało ${user.disabled ? 'odblokowane' : 'zablokowane'}`,
        });
      } catch (error) {
        this.$toasted.global.error({
          message: 'Coś poszło nie tak. Spróbuj jeszcze raz.',
        });
      }
      this.getFiltered();
    },
    fetchSort() {
      this.getFiltered();
    },
    returnBlockUserText(disabledState) {
      return disabledState ? 'Odblokuj konto' : 'Zablokuj konto';
    },
    onRoleUpdate(role) {
      this.params.role = role;
    },
  },
  computed: {
    roleIsUnselectedOrOffice() {
      return isEmpty(this.params.role)
        ? isEmpty(this.params.role)
        : isEqual(this.params.role, roles.OFFICE);
    },
    headers() {
      return this.roleIsUnselectedOrOffice ? headersWithoutStatistics : headersWithStatistics;
    },
  },
  async activated() {
    await this.init();
  },
};
</script>
<style lang="scss" scoped></style>
