<template>
  <v-container class="px-0">
    <slot name="filters"></slot>
    <v-container class="white my-4">
      <v-row
        ><v-col
          ><v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="10"
            hide-default-footer
            class="elevation-0"
            :item-class="disableClass"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            v-on="$listeners"
            :loading="loading"
          >
            <template v-slot:[`item.role`]="{ item }">
              <span> {{ adjustRole(item.role) }} </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                <template v-slot:activator="{ on }">
                  <v-icon small color="black" class="mr-2" v-on="on"> mdi-dots-vertical </v-icon>
                </template>
                <v-card>
                  <slot name="actions" v-bind:item="item"></slot>
                </v-card>
              </v-menu> </template></v-data-table></v-col
      ></v-row>
      <slot name="below"></slot>
    </v-container>
    <v-row v-if="isPaginationVisible"
      ><v-col
        ><div class="text-center">
          <v-pagination
            v-model="pageNumber"
            :length="length"
            total-visible="5"
          ></v-pagination></div></v-col
    ></v-row>
  </v-container>
</template>
<script>
import { formatDate } from '../../mixins/formatDate';
import workersRoles from '../../static/roles';

export default {
  name: 'RegistrationForms',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [formatDate],
  data() {
    return {
      roles: [
        {
          role: workersRoles.OFFICE,
          nameForUser: 'Pracownik biurowy',
        },
        {
          role: workersRoles.TRADER,
          nameForUser: 'Handlowiec',
        },
        {
          role: workersRoles.FITTER,
          nameForUser: 'Instalator',
        },
      ],
      itemsPerPage: 6,
      pageNumber: 1,
    };
  },
  watch: {
    pageNumber: function (newVal) {
      this.$emit('update:page', newVal);
    },
  },
  computed: {
    isPaginationVisible() {
      return this.$props.length > 1;
    },
  },
  methods: {
    adjustRole(role) {
      return this.roles.find((item) => item.role === role)?.nameForUser || 'N/A';
    },
    disableClass(item) {
      return item.disabled === true ? 'grey--text' : 'text-primary';
    },
  },
  created() {
    this.pageNumber = this.page;
  },
};
</script>
<style lang="scss" scoped>
.v-btn--round {
  border-radius: 4px;
}
</style>
