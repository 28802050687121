<template>
  <router-link :to="to">
    <v-list-item class="hover-item-popup">
      <v-list-item-content> {{ content }} </v-list-item-content>
    </v-list-item>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>
