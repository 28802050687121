<template>
  <v-btn
    elevation="0"
    @click="onclick"
    :loading="loading"
    :color="color ? color : 'primary'"
    class="white--text pa-4 v-size--large"
    :to="to ? to : ''"
    x-large
    :class="childclass"
    ><slot
  /></v-btn>
</template>
<script>
export default {
  name: 'PrimaryButton',
  props: ['onClick', 'loading', 'color', 'to', 'childclass'],
  computed: {
    onclick() {
      if (this.onClick) {
        return this.onClick;
      } else {
        return () => {};
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
