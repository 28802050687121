<template>
  <v-row>
    <v-col :cols="cols" :lg="colsLg" :sm="colsSm">
      <v-menu v-model="fromDateMenu" :close-on-content-click="false" offset-y max-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromDateComputed"
            :label="fromDateLabel"
            persistent-hint
            hide-details
            solo
            :prepend-icon="showPrependIcon ? 'mdi-calendar' : ''"
            readonly
            v-bind="attrs"
            v-on="on"
            :outlined="outlinedInput"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="fromDateComputed"
          no-title
          full-width
          locale="pl"
          @input="fromDateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col :cols="cols" :lg="colsLg" :sm="colsSm">
      <v-menu v-model="toDateMenu" :close-on-content-click="false" offset-y max-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toDateComputed"
            :label="toDateLabel"
            persistent-hint
            hide-details
            solo
            :prepend-icon="showPrependIcon ? 'mdi-calendar' : ''"
            readonly
            v-bind="attrs"
            v-on="on"
            :outlined="outlinedInput"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="pl"
          v-model="toDateComputed"
          full-width
          no-title
          @input="toDateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'DateRangeFilters',
  props: {
    cols: {
      type: Number,
      default: 12,
    },
    colsLg: {
      type: Number,
      default: 6,
    },
    colsSm: {
      type: Number,
      default: 6,
    },
    fromDateLabel: {
      type: String,
      default: 'Od',
    },
    toDateLabel: {
      type: String,
      default: 'Do',
    },
    fromDate: {
      default: null,
      required: true,
    },
    toDate: {
      default: null,
      required: true,
    },
    showPrependIcon: {
      type: Boolean,
      default: true,
    },
    outlinedInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fromDateMenu: false,
      toDateMenu: false,
    };
  },
  computed: {
    toDateComputed: {
      get: function () {
        return this.toDate;
      },
      set: function (date) {
        this.$emit('updateToDate', date);
      },
    },
    fromDateComputed: {
      get: function () {
        return this.fromDate;
      },
      set: function (date) {
        this.$emit('updateFromDate', date);
      },
    },
  },
};
</script>
