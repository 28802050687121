<template>
  <v-row class="align-center">
    <v-col cols="12" :sm="statisticsActive ? 2 : 3">
      <v-text-field v-model="filters.search" hide-details placeholder="Szukaj" solo></v-text-field
    ></v-col>
    <v-col cols="12" :sm="statisticsActive ? 2 : 3">
      <v-select
        v-model="basicRole"
        :items="roleSelection"
        label="Rola pracowników"
        solo
        hide-details
        item-text="nameForUser"
        item-value="role"
      ></v-select
    ></v-col>
    <v-col cols="12" :sm="statisticsActive ? 2 : 3">
      <v-select
        v-model="basicDisableState"
        :items="disableSelection"
        label="odblokowany/zablokowany"
        solo
        hide-details
        item-text="displayName"
        item-value="disableState"
      ></v-select
    ></v-col>
    <v-col cols="12" v-if="statisticsActive" :sm="4">
      <date-range-filters
        @updateFromDate="updateFromDate"
        @updateToDate="updateToDate"
        :toDate="filters.toDate"
        fromDateLabel="Statystyki od"
        toDateLabel="Statystyki do"
        :fromDate="filters.fromDate"
        :showPrependIcon="false"
        :outlinedInput="false"
      ></date-range-filters>
    </v-col>
    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn></v-col
    >
    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
        <v-icon>mdi-cancel</v-icon>
      </v-btn></v-col
    >
  </v-row>
</template>

<script>
import roles from '../../static/roles';
import DateRangeFilters from '../DateRangeFilters.vue';
export default {
  name: 'FitterFilters',
  components: { DateRangeFilters },
  props: {
    statisticsActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      basicRole: '',
      basicDisableState: false,
      roleSelection: [
        {
          role: roles.OFFICE,
          nameForUser: 'Pracownicy biurowi',
        },
        {
          role: roles.TRADER,
          nameForUser: 'Handlowcy',
        },
        {
          role: roles.FITTER,
          nameForUser: 'Instalatorzy',
        },
      ],
      disableSelection: [
        {
          disableState: false,
          displayName: 'odblokowany',
        },
        {
          disableState: true,
          displayName: 'zablokowany',
        },
        {
          disableState: '',
          displayName: 'Wszyscy',
        },
      ],
      filters: {
        search: '',
        role: '',
        disabled: false,
        fromDate: null,
        toDate: null,
      },
    };
  },
  watch: {
    basicRole: {
      handler(val) {
        this.filters.role = val;
        this.updateRole();
      },
      deep: true,
    },
    basicDisableState: {
      handler(val) {
        this.filters.disabled = val;
      },
      deep: true,
    },
  },
  methods: {
    onSearch() {
      this.$emit('search', this.filters);
    },
    clearParams() {
      this.filters = {
        search: '',
        role: '',
        disabled: false,
      };
      this.basicRole = '';
      this.basicDisableState = false;
      this.$emit('clearParams');
    },
    updateRole() {
      this.$emit('updateRole', this.filters.role);
    },
    updateFromDate(date) {
      this.filters.fromDate = date;
    },
    updateToDate(date) {
      this.filters.toDate = date;
    },
  },
};
</script>
