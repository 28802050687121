export const sortHandler = {
  data() {
    return {
      sortBy: [],
      sortDesc: [],
    };
  },
  methods: {
    async sortByFunc(sort) {
      this.sortBy = sort;
    },
    async sortDescFunc(desc) {
      this.sortDesc = desc;
      await this.sort();
    },

    async sort() {
      const isDesc = this.sortDesc[0];
      const sortBy = this.sortBy[0];

      if (this.sortBy.length > 0) {
        const sortPhrase = `${isDesc ? '-' : ''}${sortBy}`;
        this.params.sort = sortPhrase;
        await this.fetchSort();
      }
    },
  },
};
