export const officeEmployeeHeaders = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullName',
  },
  {
    text: 'Rola',
    value: 'role',
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Email', value: 'email' },
  { text: '', value: 'actions', sortable: false },
];

export const fitterEmployeeHeaders = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullName',
  },
  {
    text: 'Rola',
    value: 'role',
  },
  { text: 'Firma', value: 'companyName' },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Email', value: 'email' },
  { text: 'L. mont.', value: 'assemblyCount' },
  { text: 'kW mont.', value: 'assemblykW' },
  { text: '', value: 'actions', sortable: false },
];

export const traderEmployeeHeaders = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullName',
    sortable: false,
  },
  {
    text: 'Rola',
    value: 'role',
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Email', value: 'email' },
  { text: 'L. mont.', value: 'assemblyCount' },
  { text: 'kW mont.', value: 'assemblykW' },
  { text: '', value: 'actions', sortable: false },
];

export const headersWithStatistics = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullName',
  },
  {
    text: 'Rola',
    value: 'role',
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Email', value: 'email' },
  { text: 'L. mont.', value: 'totalNumberOfAssemblies' },
  { text: 'kW mont.', value: 'totalPowerOfAssemblies' },
  { text: '', value: 'actions', sortable: false },
];

export const headersWithoutStatistics = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullName',
  },
  {
    text: 'Rola',
    value: 'role',
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Email', value: 'email' },
  { text: '', value: 'actions', sortable: false },
];
