<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary" class="my-3" />
    <slot v-else></slot>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
